import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { UnAnalyzedAst } from 'common/types/soql';
import { AppState } from '../redux/store';
import { getUnAnalyzedAst, lastInChain } from '../lib/selectors';
import { hasAnyFilter } from '../lib/clear-all-helpers';
import ClearExpr from './ClearExpr';
import { Tab } from 'common/explore_grid/types';

interface ClearAllFiltersProps {
  clearEnabled: boolean;
}

function ClearAllFilters({ clearEnabled }: ClearAllFiltersProps) {
  const getClearedAST = (ast: UnAnalyzedAst): UnAnalyzedAst => ({
    ...ast,
    having: null,
    where: null
  });

  return (<ClearExpr
    clearEnabled={clearEnabled}
    tab={Tab.Filter}
    getClearedAST={getClearedAST} />);
}

const mapStateToProps = (state: AppState): ClearAllFiltersProps => {
  return {
    clearEnabled: hasAnyFilter(getUnAnalyzedAst(state.query).map(lastInChain))
  };
};

export default connect(mapStateToProps)(ClearAllFilters);
