import {
  AnalyzedSelectedExpression,
  ColumnRef,
  Expr,
  NoPosition,
  OrderBy,
  Scope,
  StarSelection,
  TypedExpr,
  UnAnalyzedSelectedExpression,
  UnAnalyzedSelection,
  UnAnalyzedAst,
  isColumnRef,
  isExpressionEqualIgnoringPosition,
  isFunCall
} from 'common/types/soql';
import {
  editableExpression,
  getEditableSelectedExpressions,
  getColumns,
  getCompilationProjectionInfo,
  getQueryProjectionInfo,
  getLastUnAnalyzedAst,
  hasQuerySucceeded,
  ViewColumnColumnRef
} from '../../lib/selectors';
import { QueryCompilationResult, CompilationStatus } from 'common/types/compiler';
import { scrollToPosition } from '../../lib/scroll-helpers';
import { containsAggregate, isExprStillValid, isSubExpr, qualifiedNameFromColumnRef } from '../../lib/soql-helpers';
import * as _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { some, none, Option, option } from 'ts-option';
import * as VisualContainer from '../visualContainer';
import { VisualContainerProps } from '../visualContainer';
import { isEditable } from '../VisualExpressionEditor';
import VisualGroupByList from './VisualGroupBys';
import VisualAggregateList from './VisualAggregates';
import WithHandlingOfNonVisualStates from '../visualNodes/WithHandlingOfNonVisualStates';
import { PickableColumn, ProjectionExpr, matchPicked } from '../../lib/column-picker-helpers';
import { EditableExpression, Eexpr } from 'common/explore_grid/types';
import { FeatureFlags } from 'common/feature_flags';

const dateTruncationFunctions = ['date_trunc_y', 'date_trunc_ym', 'date_trunc_ymd', 'datez_trunc_y', 'datez_trunc_ym', 'datez_trunc_ymd'];

const enableSimpleDateGrouping = FeatureFlags.value('enable_simple_date_grouping_ec');

export function dropOrderBys(selectionsToDrop: EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[], orderBys: OrderBy[]) {
  return orderBys.filter(({ expr }) => isExprStillValid(selectionsToDrop, expr));
}

export function updateHaving(selectionsToDrop: EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[], having: Expr | null) {
  if (having && isExprStillValid(selectionsToDrop, having)) {
    return having;
  }
  return null;
}

export function putGroupingInSelection(
  selection: UnAnalyzedSelection,
  grouping: Expr,
  alias: Option<string> = none
): UnAnalyzedSelection {
  /* Not required, but the user probably wants to see their newly grouped column.
   * If there is no expression in the projection which references the group by
   * column being added, we'll add it for the user. */
  const isGroupingInSelection = (selectedExpr: UnAnalyzedSelectedExpression) =>
    // Checks if group-by expr in the selection.
    isExpressionEqualIgnoringPosition(grouping, selectedExpr.expr) ||
    // Checks if group-by expr, referenced by its alias, is in the selection (for aliased calculated columns).
    (isColumnRef(grouping) && grouping.value === _.get(selectedExpr, 'name.name'));

  if (!_.some(selection.exprs, selectedExpr => isGroupingInSelection(selectedExpr))) {
    let maybeName = null;

    // we want to gate this behavior the enable_simple_date_grouping_ec ff
    if (enableSimpleDateGrouping) {
      if (alias.nonEmpty) {
        maybeName = { name: alias.get, position: NoPosition };
      } else {
        // here we want to generate an alias for the new date function if its a date truncation function
        maybeName = getTruncationAliasForGroupBy(grouping);
      }
    } else {
      // remove me when removing enable_simple_date_grouping_ec ff
      maybeName = alias.map(name => ({ name, position: NoPosition })).orNull;
    }
    return {
      all_system_except: null,
      all_user_except: [],
      exprs: [
        ...selection.exprs,
        { expr: grouping, name: maybeName }
      ]
    };
  }
  return selection;
}

const getApiFunctionNameForGroupBy = (functionName: string) => {
  switch (functionName) {
    case 'date_trunc_y':
    case 'datez_trunc_y':
      return 'by_year';
    case 'date_trunc_ym':
    case 'datez_trunc_ym':
      return 'by_month';
    case 'date_trunc_ymd':
    case 'datez_trunc_ymd':
      return 'by_day';
    default:
      return functionName;
  }
};

const getTruncationAliasForGroupBy = (groupBy: Expr) => {
  if (isFunCall(groupBy) && dateTruncationFunctions.includes(groupBy.function_name)) {
    const colRef = groupBy.args.find(v => isColumnRef(v));
    if (colRef) {
      return { position: NoPosition, name: `${getApiFunctionNameForGroupBy(groupBy.function_name)}_${qualifiedNameFromColumnRef(colRef as ColumnRef)}` };
    }
  }
  return null;
};

// Okay, this is called in a lot of places. We need to test all those places.
export function buildSelection(
  projection: UnAnalyzedSelectedExpression[],
  groupBys: Expr[],
  vccrs?: ViewColumnColumnRef[]
): UnAnalyzedSelection {
  let allUserExcept: StarSelection[] = [];
  let selectedExprs = projection;
  if (_.isEmpty(selectedExprs) && _.isEmpty(groupBys)) {
    // When all groups and aggregates have been cleared...
    if (!_.isUndefined(vccrs) && !_.isEmpty(vccrs)) { // show all columns if columns exist
      selectedExprs = vccrs.map(vccr => {
        const ref = vccr.ref;
        const name = (ref.qualifier) ? { position: NoPosition, name: `${qualifiedNameFromColumnRef(ref)}` } : null;
        return { expr: ref, name };
      });
    } else { // or fall back to SELECT *
      allUserExcept = [{
        qualifier: null,
        exceptions: []
      }];
    }
  } else if (_.isEmpty(selectedExprs) && !_.isEmpty(groupBys)) {
    // if there are group bys, the only selected exprs can be those groups
    // this may not be what we want
    // we want to gate this behavior the enable_simple_date_grouping_ec ff
    if (enableSimpleDateGrouping) {
      selectedExprs = groupBys.map(gb => {
        // here we add an alias if the user has chosen a function to add to the group by (currently only by day/month/year truncations)
        return { expr: gb, name: getTruncationAliasForGroupBy(gb) };
      });
    } else {
      // remove me when removing enable_simple_date_grouping_ec ff
      selectedExprs = groupBys.map(gb => ({ expr: gb, name: null }));
    }
  }

  return {
    all_system_except: null,
    all_user_except: allUserExcept,
    exprs: selectedExprs
  };
}

export function addGroupBy(
  ast: UnAnalyzedAst,
  selectedExpressions: EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[],
  picked: PickableColumn,
  scope: Scope
): UnAnalyzedAst {
  /* When grouping by a dataset column, its real field name is used. It is not referred to by its alias, even if it exists.
   * When grouping by a query column, we may use its alias or underyling expression. The alias takes precedence over the
   * underyling expression. */
  const { alias, expr } = matchPicked(
    picked,
    (vccr: ViewColumnColumnRef) => ({ alias: none as Option<ColumnRef>, expr: vccr.ref as Expr }),
    (pexpr: ProjectionExpr) => ({ alias: pexpr.ref, expr: pexpr.expr })
  );

  const groupings = [...ast.group_bys, alias.getOrElseValue(expr as any) as Expr];
  const aggregateSelections = selectedExpressions.filter(se => containsAggregate(scope, se.untyped.expr));
  const selection = putGroupingInSelection(
    buildSelection(
      // Find all of the selections that should be kept. This includes:
      selectedExpressions.filter(se => {
        // Find the alias of the selected expression if it exists.
        const tempAlias = (isColumnRef(se.untyped.expr) || se.untyped.name === null) ? none : some({ value: se.typed.name, qualifier: null, type: 'column_ref' } as Expr);

        return _.some([...ast.group_bys, expr], gb =>
          // Any selected expr that is a sub-expression of the group-by expr
          isSubExpr(gb, se.untyped.expr) ||
          // The counterpart of a group-by aliased-column-ref expr
          (isColumnRef(gb) && isColumnRef(se.typed.expr) && gb.value === se.typed.name && gb.qualifier === se.typed.expr.qualifier)) ||
          // Any aggregate expressions
          containsAggregate(scope, se.untyped.expr) ||
          // Any expressions needed by the aggregate expressions
          tempAlias.map(cref => _.find(aggregateSelections, (ase) => isSubExpr(cref, ase.untyped.expr)) !== undefined).getOrElseValue(false);
      }).map(ee => ee.untyped),
      [...ast.group_bys, expr]
    ),
    expr,
    alias.map(ref => ref.value)
  );

  const orderBys = ast.order_bys.filter(ordering => (
    _.some(groupings, grouping => isExpressionEqualIgnoringPosition(ordering.expr, grouping))
  ));

  return {
    ...ast,
    selection,
    group_bys: groupings,
    order_bys: orderBys
  };
}

interface State {
  scrollPosition: Option<number>;
}

class VisualGroupAggregateEditor extends React.Component<VisualContainerProps, State> {
  state = {
    scrollPosition: none as Option<number>
  };

  getSnapshotBeforeUpdate(): Option<number> {
    // Grab the scroll position we're at before we update.
    const element = document.querySelector('.scroll-container > div');
    if (element) {
      return some(element.getBoundingClientRect().top);
    }
    return none;
  }

  componentDidUpdate(prevProps: VisualContainerProps, prevState: State, snapshot: Option<number>) {
    this.props.query.compilationResult.map((result: QueryCompilationResult) => {
      if (result.type === CompilationStatus.Started && !this.state.scrollPosition.isDefined && snapshot.isDefined) {
        // We show a compiling message after an edit, which is what loses the scroll position.
        // So we save the scroll position we had just before compilation to state.
        this.setState({ scrollPosition: snapshot });
      } else if (result.type === CompilationStatus.Succeeded && this.state.scrollPosition.isDefined) {
        // Once compilation succeeds and we're showing the VEE again, scroll to the saved position.
        scrollToPosition('.scroll-container > div', this.state.scrollPosition.getOrElseValue(0));
        this.setState({ scrollPosition: none });
      }
    });
  }

  isCompiling = (): boolean => {
    return this.props.query.compilationResult.map(cr => cr.type === 'started').getOrElseValue(false);
  };

  getEditableGroupBys = (): Option<Eexpr<Expr, TypedExpr>[]> => {
    return editableExpression(
      this.props.query.compilationResult,
      (unanalyzed) => option(unanalyzed.group_bys),
      (analyzed) => option(analyzed.group_bys)
    ).map((eexpr: Eexpr<Expr[], TypedExpr[]>) => {
      if (isEditable(eexpr)) {
        return _.zip(eexpr.untyped, eexpr.typed).flatMap(([untyped, typed]) => {
          if (untyped && typed) return [{ untyped, typed }];
          return [];
        });
      } else {
        return eexpr.untyped.map(un => ({ untyped: un, error: eexpr.error }));
      }
    });
  };

  getGroupBys = (): Expr[] => {
    return getLastUnAnalyzedAst(this.props.query)
      .map(ast => ast.group_bys)
      .getOrElseValue([]);
  };

  getSelectedExprs = (): UnAnalyzedSelectedExpression[] => {
    return getLastUnAnalyzedAst(this.props.query)
      .map(ast => ast.selection.exprs)
      .getOrElseValue([]);
  };

  addGroupBy = (picked: PickableColumn) => {
    getLastUnAnalyzedAst(this.props.query).forEach(ast => {
      getEditableSelectedExpressions(this.props.query).forEach(selectedExpressions => {
        this.props.compileAST(addGroupBy(ast, selectedExpressions, picked, this.props.scope), true);
      });
    });
  };

  render() {
    const { query, parameters } = this.props;
    const querySucceeded = hasQuerySucceeded(query);
    const columns = getColumns(query);
    const queryProjectionInfo = getQueryProjectionInfo(query);
    const projectionInfo = (queryProjectionInfo.isDefined) ? queryProjectionInfo : getCompilationProjectionInfo(query);

    return (
      <div className="grid-datasource-components scroll-container">
        <WithHandlingOfNonVisualStates query={query}>
          {
            getLastUnAnalyzedAst(this.props.query).map<JSX.Element | null>(ast => (
              <div key="quiet-eslint">
                <VisualGroupByList
                  ast={ast}
                  addGroupBy={this.addGroupBy}
                  columns={columns}
                  parameters={parameters}
                  compileAST={this.props.compileAST}
                  editableGroupBys={this.getEditableGroupBys()}
                  projectionInfo={projectionInfo}
                  querySucceeded={querySucceeded}
                  scope={this.props.scope}
                  selectedExpressions={getEditableSelectedExpressions(query)} />
                <VisualAggregateList
                  ast={ast}
                  columns={columns}
                  parameters={parameters}
                  compileAST={this.props.compileAST}
                  projectionInfo={projectionInfo}
                  querySucceeded={querySucceeded}
                  scope={this.props.scope}
                  selectedExpressions={getEditableSelectedExpressions(query)} />
              </div>
            )).orNull
          }
        </WithHandlingOfNonVisualStates>
      </div>
    );
  }
}

export default connect(
  VisualContainer.mapStateToProps,
  VisualContainer.mapDispatchToProps,
  VisualContainer.mergeProps
)(VisualGroupAggregateEditor);
