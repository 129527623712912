import React, { Fragment, ReactNode } from 'react';
import _ from 'lodash';

import Checkbox from 'common/components/Checkbox';
import ColumnTypeIcon from 'common/components/SoQLTypeIcon';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import { TypedExpr, isLiteral } from 'common/types/soql';
import { ViewColumn } from 'common/types/viewColumn';

import { ContextualEventHandlers } from '../../redux/store';
import { NavigateWithUnappliedChanges } from '../UnappliedChangesModalWrapper';
import { keyForProjectableTerm, ProjectableTerm } from './common';
import SortIndex from './SortIndex';
import SortSelector from './SortSelector';
import { VQEColumn } from 'common/explore_grid/redux/store';

const t = (key: string, options: Record<string, unknown> = {}): string => I18n.t(key, { scope: 'shared.explore_grid.visual_column_manager', ...options });

export interface ColumnRowFragmentProps {
  idx: number;
  projectableTerm: ProjectableTerm;
  showDatasetColumn: boolean;
  columnBehaviors: {
    toggleColumn: (projectableTerm: ProjectableTerm) => void;
    addOrReplaceOrderBy: (expr: TypedExpr, ascending: boolean, sortIndex: number) => void;
    clearOrderBy: (sortIndex: number) => void;
    reorderOrderBy: (sortIndex: number, newSortIndex: number) => void;
  };
  orderByCount: number;
  showIncludeCheckbox: boolean;
  toEditColumnMetadata?: ContextualEventHandlers['editColumnMetadata'];
  toFormatColumn?: ContextualEventHandlers['formatColumn'];
  columnUpdated: (updatedColumn?: VQEColumn) => void;
  navigateWithUnappliedChanges: NavigateWithUnappliedChanges;
}

// the TDs that will make up the row, minus the drag handler, which will be added by DragDropContainer
const getColumnRowFragment = (props: ColumnRowFragmentProps): ReactNode => {
  const {
    showDatasetColumn,
    showIncludeCheckbox,
    projectableTerm: term,
    toEditColumnMetadata,
    toFormatColumn,
    columnUpdated,
    navigateWithUnappliedChanges,
  } = props;
  const { sortIndex } = term.sortData;
  const showSortColumns = term.projectionIndex > -1 && !term.expr.map(e => e.expr).filter(isLiteral).isDefined;

  const columnTypeIcon = term.expr.map(expr => expr.expr).orElseValue(term.typedRef).match({
    some: (typedExpr) => <ColumnTypeIcon type={typedExpr.soql_type} />,
    none: () => null
  });

  const columnName = term.metadataColumn.map(mc => mc.name)
    .orElse(() => term.viewColumn.map(vc => vc.name))
    .orElse(() => term.expr.map(expr => expr.name))
    .getOrElseValue('');
  const apiFieldName = term.metadataColumn.map(mc => mc.fieldName)
    .orElse(() => term.viewColumn.map(vc => vc.fieldName))
    .orElse(() => term.expr.map(expr => expr.name))
    .getOrElseValue('');

  const hoverToEdit = (metadataType: 'fieldName' | 'name', column: VQEColumn): JSX.Element | null => {
    if (term.projectionIndex === -1) return null; // if deselected, you can't edit the metadata.
    if (!toEditColumnMetadata) return null; // if we don't know how to edit metadata, then remove for user too.

    const onClick = (evt: React.SyntheticEvent<HTMLAnchorElement, MouseEvent>) => {
      evt.preventDefault();
      navigateWithUnappliedChanges(() => { toEditColumnMetadata(metadataType, column); });
    };
    const scope = 'shared.explore_grid.grid_column_header';
    return (<div className="hover-to-edit"><a href="#" onClick={onClick} title={t('click_to_edit_metadata', { scope })}>
      {<SocrataIcon name={IconName.Edit} />}
    </a></div>);
  };

  const columnFormatter = term.metadataColumn.map(mc => {
    if (term.projectionIndex === -1) return null; // if deselected, you can't edit the metadata.
    if (!toFormatColumn) return null;

    const onClick = (evt: React.SyntheticEvent<HTMLAnchorElement, MouseEvent>) => {
      evt.preventDefault();
      navigateWithUnappliedChanges(() => { toFormatColumn(mc, columnUpdated); }, { navigateOnApply: true });
    };

    return (<td className="column-formatting" key="shut-up-eslint">
      <a href="#" onClick={onClick} title={t('column_formatting')}>
        <SocrataIcon name={IconName.ParagraphLeft} />
      </a>
    </td>);
  }).getOrElseValue(null);

  return (
    <Fragment>
      { showIncludeCheckbox && <td className='include-checkbox'>
        <Checkbox
          id={`include-column-${keyForProjectableTerm(term)}`}
          ariaLabel={t(term.projectionIndex > -1 ? 'exclude_column_aria_label' : 'include_column_aria_label', { columnName })}
          checked={term.projectionIndex > -1}
          onChange={() => props.columnBehaviors.toggleColumn(term)} />
      </td>}
      <td className='column-name'>
        <div className='wrapper'>
          {columnTypeIcon}
          {columnName}
          {term.metadataColumn.map(vc => hoverToEdit('name', vc)).getOrElseValue(null)}
        </div>
      </td>
      <td className='column-field-name'>
        <div className='wrapper'>
          {apiFieldName}
          {term.metadataColumn.map(vc => hoverToEdit('fieldName', vc)).getOrElseValue(null)}
        </div>
      </td>
      { showDatasetColumn &&
        <td className='dataset-name'>{term.view.match({ some: v => v.name, none: () => ''})}</td>}
      <td className='sort-selector' onClick={(sortClickEvent) => {
        // oops, there's vanilla javascript in this react!!
        // the transform: translate3d(0, 0, 0); fixes the drag preview on Chrome
        // but it also makes the drop down non-functional, so here we temporarily remove it
        // trying to remove it as a part of the react state-workflow is unfortunately not fast enough
        const sortTr = sortClickEvent.currentTarget.closest('tr') as HTMLElement;
        sortTr.style.transform = 'none';

        // we want to make sure the transform gets re-set if user is dragging
        // if you actually sort, it will be reset by the component re-rendering
        // but if you close the menu without sorting, then this mousedown will fix it
        const dragHandle = sortTr.querySelector('.drag-enabled') as HTMLTableCellElement | null;
        if (dragHandle != null) {
          dragHandle.addEventListener('mousedown', (dragMouseDownEvent: MouseEvent) => {
            if (dragMouseDownEvent.target != null && dragMouseDownEvent.target instanceof Element) {
              const dragTr = dragMouseDownEvent.target.closest('tr') as HTMLElement;
              dragTr.style.transform = 'translate3d(0, 0, 0)';
            }
          });
        }
      }}>
        {showSortColumns && <SortSelector
          id={`sort-selector-${props.idx}`}
          projectableTerm={term}
          addOrReplaceOrderBy={props.columnBehaviors.addOrReplaceOrderBy}
          clearOrderBy={props.columnBehaviors.clearOrderBy}
        />}
      </td>
      <td className='sort-order'>
        {showSortColumns && sortIndex > -1 && <SortIndex
          projectableTerm={term}
          orderByCount={props.orderByCount}
          reorderOrderBy={props.columnBehaviors.reorderOrderBy}
        />}
      </td>
      {toFormatColumn && columnFormatter}
    </Fragment>
  );
};

export default getColumnRowFragment;
