import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { some, none, Option } from 'ts-option';

import airbrake from 'common/airbrake';
import { PUBLICATION_STAGE } from 'common/views/constants';
import { View } from 'common/types/view';
import { isDefaultView } from 'common/views/view_types';
import { assetIsDraft, copyTabularSoQLView } from 'common/views/helpers';
import { getCurrentUser } from 'common/current_user';
import type { GuidanceSummaryV2 } from 'common/types/approvals';
import { fetchApprovalsGuidanceV2, withGuidanceV2 } from 'common/core/approvals/index_new';
import { soqlRendering } from 'common/types/soql';
import AssetActionBar, { AssetActionBarProps } from 'common/components/AssetActionBar';
import { PublishButton }
  from 'common/components/AssetActionBar/components/publication_action/publish_button';
import SubmitForApprovalButton from 'common/components/AssetActionBar/components/submit_for_approval_button';
import { showErrorToastNow } from 'common/components/ToastNotification/Toastmaster';
import I18n from 'common/i18n';

import { AppState } from '../redux/store';
import { QueryStatus } from 'common/explore_grid/types';
import { hasQuerySucceeded } from '../lib/selectors';
import CreateView from './CreateView';
import SaveViewButton from './SaveViewButton';

interface StateProps {
  view: View;
  queryString: Option<string>;
  querySucceeded: boolean;
  canReadFromAllParents: boolean;
  mainParent: Option<View>;
}

interface State {
  approvalsGuidance?: GuidanceSummaryV2;
}

// AssetActionBar customized for ExploreGrid (basically imitating the save/publish enable/disable that viz can does).
export class ExploreGridAssetActionBar extends Component<StateProps, State> {

  state: State = {
    approvalsGuidance: undefined,
  };

  componentDidMount() {
    fetchApprovalsGuidanceV2(this.props.view.id).then(guidance => {
      this.setState({ approvalsGuidance: guidance });
    }).catch(error => {
      // 404s are expected if the asset is being viewed on a federation target domain
      if (_.get(error, 'response.status') !== 404) {
        // Notify but don't re-raise - the application will continue to work with the asset action bar disabled.
        airbrake.notify({
          error,
          context: 'Guidance failed to fetch. We will not override the AAB default primary button on this VQE'
        });
      }
    });
  }

  onCopy = async (viewName: string) => {
    try {
      const siblingView = await copyTabularSoQLView(this.props.view, viewName);

      if (!siblingView || !siblingView.id) {
        throw new Error(`Error copying view; got ${siblingView}`);
      }

      window.open(`/d/${siblingView.id}/explore`, '_blank');
    } catch (error) {
      console.error('Could not copy view', error);
      showErrorToastNow(I18n.t('shared.components.asset_action_bar.create_view_failed'));
    }
  };

  render() {
    const {
      view,
      queryString,
      querySucceeded,
      canReadFromAllParents,
      mainParent,
    } = this.props;
    const { approvalsGuidance } = this.state;

    const user = getCurrentUser();
    if (user === undefined || approvalsGuidance === undefined ) {
      // anonymous users can't save, create, or publish things
      return null;
    }

    const LeftButton = view.publicationStage === PUBLICATION_STAGE.PUBLISHED ? CreateView : SaveViewButton;
    const aabProps: AssetActionBarProps = {
      approvalsGuidance,
      inVQE: true,
      user,
      view
    };

    if (!isDefaultView(view) && canReadFromAllParents && mainParent.isDefined) {
      aabProps.onCopy = this.onCopy;
      mainParent.map(p => {
        aabProps.copyBasedOn = p.name;
      });
    }

    // Set the method for rendering the primary button.
    if (approvalsGuidance) {
      const approvalsHelper = withGuidanceV2(approvalsGuidance);
      const isDirty = queryString.map(changed => changed !== view.queryString).getOrElseValue(false);
      const isDraftAndNotPending = (
        !approvalsHelper.isPending() &&
        assetIsDraft({ coreView: view, isRevision: false, isDraftStory: false })
      );
      // EN66455 FOLLOW UP: Verify that this is the correct way we want to check requires approval
      const requiresApproval = approvalsHelper.canSubmitUpdatePublishedAssetRequest();

      if (requiresApproval) {
        aabProps.renderPrimaryButton = () => (
          <SubmitForApprovalButton approvalsGuidance={approvalsGuidance} disabled={isDirty} />);
      } else if (isDraftAndNotPending) {
        const disabledReason = I18n.t('shared.components.asset_action_bar.publication_action.update_without_saving_error');
        aabProps.renderPrimaryButton = (props: any) => (
          <PublishButton
            {...props}
            disabled={isDirty}
            disabledReason={disabledReason} />
        );
      }
    }

    return (
      <AssetActionBar {...aabProps}>
        <LeftButton
          view={view}
          querySucceeded={querySucceeded}
          newQueryString={queryString}
        />
      </AssetActionBar>
    );
  }
}

function mapStateToProps(state: AppState): StateProps {
  const { view, parentInfo: { canReadFromAllParents, mainParent } } = state;
  const querySucceeded = hasQuerySucceeded(state.query);
  const queryString = state.query.queryResult.match({
    some: (result) => {
      return result.type === QueryStatus.QUERY_SUCCESS
        ? some(soqlRendering.unwrap(result.compiled.rendering))
        : none;
    },
    none: () => none
  });

  return {
    view,
    queryString,
    querySucceeded,
    canReadFromAllParents,
    mainParent
  };
}

export default connect(mapStateToProps)(ExploreGridAssetActionBar);
