import _ from 'lodash';
import React from 'react';
import { validateColumnFieldName } from 'common/column/utils';
import { fetchTranslation } from 'common/locale';
import {
  Expr,
  FunCall,
  NoPosition,
  Scope,
  SoQLFunCall,
  SoQLType,
  UnAnalyzedSelectedExpression,
  UnAnalyzedAst,
  nullLiteral
} from 'common/types/soql';
import { ProjectionInfo, ViewColumnColumnRef, getOnlyAggregates } from '../../lib/selectors';
import { ColumnType, isQueryColumn, matchPicked, PickableColumn, ProjectionExpr, Selected } from '../../lib/column-picker-helpers';
import { existingFieldNames, hasGroupOrAggregate, toTyped } from '../../lib/soql-helpers';
import { none, Option, option, some } from 'ts-option';
import AggregateFunPicker from './AggregateFunPicker';
import { CompileAST } from '../visualContainer';
import ExpressionEditor from '../VisualExpressionEditor';
import { StatefulEdit } from '../visualNodes/EditLiteral';
import ColumnPicker from '../ColumnPicker';
import RemoveNode from '../RemoveNode';
import KebabMenu from '../../components/visualNodes/KebabMenu';
import { ClientContextVariable } from 'common/types/clientContextVariable';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.visual_aggregates');

interface ValidationErrorProps {
  error: string;
}

const ValidationError = ({ error }: ValidationErrorProps) => (<div className="validation-error">{error}</div>);

interface EditAggregateNameProps {
  value: string;
  onChange: (n: string) => void;
  errors: string[];
}

export function EditAggregateName(props: EditAggregateNameProps) {
  return (
    <div className="aggregate-name">
      {t('api_field_name')}: <StatefulEdit {...props} className="edit-aggregate-name" label={t('api_field_name')} />
      {props.errors.map((err, index) => <ValidationError key={index} error={err} />)}
    </div>
  );
}

type FieldName = Option<string>;

// Find the available scope based on chosen column.
const findAvailableScope = (picked: PickableColumn, scope: Scope): Scope => {
  const soqlType = (isQueryColumn(picked)) ? picked.column.typedExpr.soql_type as SoQLType : picked.column.typedRef.soql_type as SoQLType;
  return scope.filter(fs => {
    const constraints = _.flatMap(fs.constraints.a || []); // TODO: EN-44287
    return _.isEmpty(constraints) || constraints.includes(soqlType);
  });
};

// Find the constraints based on the funcall.
const findConstraints = (funcall: FunCall, scope: Scope): SoQLType[] => {
  const fs = _.find(scope, (funspec) => funspec.name === funcall.function_name);
  if (!fs) { return []; }
  return _.flatMap(fs.constraints.a || []); // TODO: EN-44287
};

const toSelected = (column: Option<PickableColumn>): Option<Selected> => (
  column.map(picked => (picked.column))
);

export interface Props {
  ast: UnAnalyzedAst;
  scope: Scope;
  columns: ViewColumnColumnRef[];
  parameters: ClientContextVariable[];
  compileAST: CompileAST;
  projectionInfo: ProjectionInfo;
  onHideAggregateAddExpr: () => void;
}

export interface State {
  column: Option<PickableColumn>;
  funcall: Option<FunCall>;
  name: FieldName;
  errors: string[];
}

export default class AggregateAddExpr extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      column: none,
      funcall: none,
      name: none,
      errors: []
    };
  }

  attemptAddAggregate = (column: Option<PickableColumn>, funcall: Option<FunCall>, name: FieldName) => {
    const { ast, projectionInfo, scope } = this.props;
    const errors = name.map(n => validateColumnFieldName(n, existingFieldNames(ast, projectionInfo, scope))).getOrElseValue([]);
    if (_.isEmpty(errors)) {
      funcall.match({
        some: (fun) => {
          switch (fun.function_name) {
            case SoQLFunCall.CountStar:
              this.addAggregate(none, fun, name);
              break;
            default:
              column.map(_col => this.addAggregate(column, fun, name));
          }
        },
        none: () => _.noop()
      });
    }

    this.setState({ errors });
  };

  onSelectColumn = (picked: PickableColumn) => {
    const column = option(picked);
    this.attemptAddAggregate(column, this.state.funcall, this.state.name);
    this.setState({ column });
  };

  onSelectFunction = (fc: FunCall) => {
    const funcall = option(fc);
    this.attemptAddAggregate(this.state.column, funcall, this.state.name);
    this.setState({ funcall });
  };

  onNameChange = (value: string) => {
    const name = value ? option(value) : none;
    this.attemptAddAggregate(this.state.column, this.state.funcall, name);
    this.setState({ name });
  };

  addAggregate = (column: Option<PickableColumn>, fc: FunCall, fn: FieldName) => {
    const { ast, compileAST } = this.props;
    const scope = getOnlyAggregates(this.props.scope);
    const name = fn.map((n) => ({ name: n, position: NoPosition })).orNull;
    /* Find the sub expr for the aggregate function. When the chosen aggregate
     * call is CountStar, a sub expr is not necessary. When trying to aggregate
     * a calculated column, check if it has as a ref available (implying it is
     * aliased) before defaulting to the underlying expr. */
    const subExpr = column.map((picked) => (
      matchPicked(
        picked,
        (vccr: ViewColumnColumnRef) => vccr.ref,
        (pexpr: ProjectionExpr) => pexpr.ref.getOrElseValue(pexpr.expr as any) as Expr
      )
    )).orNull;

    const newAggregate: UnAnalyzedSelectedExpression = {
      expr: {
        type: 'funcall',
        function_name: fc.function_name,
        args: (subExpr) ? [subExpr] : [],
        window: null
      },
      name
    };

    /* When adding an aggregate, if aggregating on an aliased column,
     * do not remove the associating aliased expr from the selection.
     * e.g. select 1 as num => select 1 as num, count(num) */
    const exprs = column.map(picked => {
      return matchPicked(
        picked,
        (vccr: ViewColumnColumnRef) => [],
        (pexpr: ProjectionExpr) => {
          const { expr, ref } = pexpr;
          return ref.flatMap(cref =>
            this.props.projectionInfo.map(pi =>
              pi.unanalyzed.exprs.filter(un => _.isEqual(_.get(un, 'name.name'), cref.value))))
            .getOrElseValue([]);
        }
      );
    }).getOrElseValue([]);

    if (hasGroupOrAggregate(some(ast), scope)) {
      const [keep, _dropped] = _.partition(exprs, expr =>
        _.isUndefined(
          _.find(
            ast.selection.exprs,
            (se) => _.isEqual(_.get(se, 'name.name'), _.get(expr, 'name.name'))
          )
        )
      );
      compileAST({
        ...ast,
        selection: {
          ...ast.selection,
          exprs: [
            ...keep,
            ...ast.selection.exprs,
            newAggregate
          ]
        }
      }, true);
    } else {
      compileAST({
        ...ast,
        selection: {
          ...ast.selection,
          all_user_except: [],
          exprs: [
            ...exprs,
            newAggregate
          ]
        }
      }, true);
    }
  };

  onUpdateType = (newExpr: Expr) => {
    if (newExpr.type === 'column_ref') {
      this.setState({ column: none });
    } else {
      this.onUpdateExpr(newExpr);
    }
  };

  onUpdateExpr = (newExpr: Expr) => {
    this.onSelectColumn({
      type: ColumnType.Query,
      column: {
        expr: newExpr,
        typedExpr: toTyped(newExpr, this.props.scope, this.props.projectionInfo),
        name: 'new_aggregate', // not used, but required by PickableColumn type
        ref: none
      }
    });
  };

  // Why no dates here? Since 'Use date' is technically a function it was messing up the UI as it was double wrapping the function with two column-picker-containers
  //  and two kebabs. I failed to untangle it and since it wasn't a supported use case to make the initial filters when you add one a raw date value (you can still make it a date column or param)
  //  I just decided to remove the option from the kebab menu. This is something I would like to revisit.
  isTypeAllowed = (type: SoQLType) => (type !== SoQLType.SoQLFloatingTimestampT && type !== SoQLType.SoQLFloatingTimestampAltT);

  render = () => {
    const { columns, projectionInfo, scope, parameters } = this.props;
    const { column } = this.state;
    const onlyAggregates = getOnlyAggregates(scope);
    const availableScope = this.state.column.map(c => findAvailableScope(c, onlyAggregates)).getOrElseValue(onlyAggregates);
    const constraints = _.uniq(this.state.funcall.map(fc => findConstraints(fc, onlyAggregates)).getOrElseValue([]));

    // We need an Eexpr to show the ExpressionEditor: only used when our saved column is
    // a QueryColumn with a non-column-ref expr.
    const { showExprEditor, eexpr } = column.map(c => {
      if (isQueryColumn(c) && c.column.expr.type !== 'column_ref' && c.column.ref.isEmpty) {
        return {
          eexpr: { typed: c.column.typedExpr, untyped: c.column.expr },
          showExprEditor: true
        };
      } else {
        return {
          eexpr: { typed: null, untyped: nullLiteral },
          showExprEditor: false
        };
      }
    }).getOrElseValue({
      eexpr: { typed: null, untyped: nullLiteral },
      showExprEditor: false
    });

    return (
      <div>
        <span className="add-expr aggregate-by-blank-state add-expr-container">
          <div className="column-picker-container">
            {showExprEditor ? <ExpressionEditor
              update={this.onUpdateExpr}
              remove={_.noop}
              columns={columns}
              parameters={parameters}
              scope={scope}
              isTypeAllowed={soqlType => true}
              eexpr={eexpr}
              showRemove={false}
              projectionInfo={projectionInfo} />
            : <ColumnPicker
              className="btn btn-default add-expr-column-picker aggregate-column-picker"
              prompt={t('select_column')}
              columns={columns}
              selected={toSelected(this.state.column)}
              projectionInfo={projectionInfo}
              onSelect={this.onSelectColumn}
              soqlTypeConstraints={constraints} />}
            <KebabMenu
              columns={columns}
              parameters={parameters}
              isTypeAllowed={this.isTypeAllowed}
              scope={scope}
              update={this.onUpdateType}
            />
          </div>
          <AggregateFunPicker
            prompt={t('select_calculation')}
            scope={availableScope}
            selected={this.state.funcall}
            onSelectFunction={this.onSelectFunction} />
          <RemoveNode onClick={this.props.onHideAggregateAddExpr} />
        </span>
        <EditAggregateName
          value={this.state.name.getOrElseValue('')}
          onChange={this.onNameChange}
          errors={this.state.errors} />
      </div>
    );
  };
}
