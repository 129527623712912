import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Option } from 'ts-option';
import { ForgeButton } from '@tylertech/forge-react';

import { assign as windowLocationAssign } from 'common/window_location';
import I18n from 'common/i18n';
import { showErrorToastNow } from 'common/components/ToastNotification/Toastmaster';
import { createNewChildDraft } from 'common/views/helpers';
import { View } from 'common/types/view';

import { AppState, VQEColumn } from '../redux/store';
import CreateViewModal from './CreateViewModal';

const t = (k: string) => I18n.t(k, { scope: 'shared.components.asset_action_bar' });

interface StateProps {
  defaultQuery: string;
  columns: VQEColumn[];
}

interface CreateViewProps {
  view: View;
  querySucceeded: boolean;
  newQueryString: Option<string>;
}

type Props = CreateViewProps & StateProps;

interface CreateViewState {
  busy: boolean;
  modalOpen: boolean;
  viewName: string;
}

export class CreateView extends Component<Props, CreateViewState> {
  state = {
    busy: false,
    modalOpen: false,
    viewName: ''
  };

  /* eslint @typescript-eslint/no-shadow: "warn" */
  redirectTo(url: string) {
    windowLocationAssign(url);
  }

  onSave = (e: React.FormEvent | React.MouseEvent) => {
    if (e) e.preventDefault();
    if (this.state.viewName.length === 0) return;

    this.setState({ busy: true });
    this.props.newQueryString.forEach(async (queryString) => {
      try {
        const redirectPath = await createNewChildDraft(
          { ...this.props.view, columns: this.props.columns },
          queryString,
          this.state.viewName
        );
        this.redirectTo(redirectPath);
      } catch (error) {
        console.error(error);
        showErrorToastNow(I18n.t('shared.components.asset_action_bar.create_view_failed'));
        // Only clear the busy state if there's an error so the user can try again
        // If creation succeeds, keep the busy state so the user doesn't click things
        // while the new page is loading.
        this.setState({ busy: false });
      }
    });
  };

  render() {
    const { newQueryString, defaultQuery, view, querySucceeded } = this.props;

    const showSaveAs = newQueryString.map((query) => query !== defaultQuery).getOrElseValue(false);
    const disabled = newQueryString.isEmpty || !querySucceeded;

    return (
      <div>
        <ForgeButton type="raised" onClick={() => this.setState({ modalOpen: true })}>
          <button type="button" disabled={disabled}>
            {showSaveAs ? t('save_as') : t('create_view')}
          </button>
        </ForgeButton>
        <CreateViewModal
          isOpen={this.state.modalOpen}
          busy={this.state.busy}
          currentViewName={view.name}
          newViewName={this.state.viewName}
          onChange={(viewName) => this.setState({ viewName })}
          onDismiss={() => this.setState({ modalOpen: false })}
          onSave={this.onSave}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    defaultQuery: state.saveInfo.defaultQuery,
    columns: state.columns
  };
};

export default connect(mapStateToProps)(CreateView);
