import withWindowSize, { DimensionProps } from 'common/components/hocs/withWindowSize';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { fetchTranslation } from 'common/locale';
import * as Actions from '../redux/actions';
import { AppState } from '../redux/store';
import React from 'react';
import { connect } from 'react-redux';
import '../styles/grid-sidebar.scss';
import SearchView from './SearchView';
import * as VisualContainer from './visualContainer';
import { ExternalProps } from './visualContainer';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.grid_sidebar');

type GridSidebarStateProps = VisualContainer.VisualContainerStateProps & { isSidebarOpen: boolean };
type GridSidebarDispatchProps = VisualContainer.VisualContainerDispatchProps & { toggleSidebar: () => void };
export type GridSidebarProps = VisualContainer.VisualContainerProps & {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
};

export function GridSidebar(props: GridSidebarProps & DimensionProps) {
  if (props.mediaSize === 'small') return null;

  const { toggleSidebar, isSidebarOpen } = props;

  const renderToggle = () => (
    <a onClick={toggleSidebar} className="toggle-sidebar">
      <SocrataIcon name={isSidebarOpen ? IconName.Close2 : IconName.Search} />
    </a>
  );

  const renderCollapsed = () => <div className="sidebar-minimized">{renderToggle()}</div>;

  const renderFull = () => (
    <div>
      <div className="sidebar-heading">
        <h4>{t('in_this_dataset')}</h4>
        {renderToggle()}
      </div>
      {/* @ts-expect-error Post TypeScript and Eslint type error */}
      <SearchView />
    </div>
  );

  const inner = isSidebarOpen ? renderFull() : renderCollapsed();

  return <div className="grid-sidebar">{inner}</div>;
}

export default connect(
  (state: AppState, props: ExternalProps): GridSidebarStateProps => {
    return {
      ...VisualContainer.mapStateToProps(state, props),
      isSidebarOpen: state.isSidebarOpen
    };
  },
  (dispatcher: Actions.Dispatcher): GridSidebarDispatchProps => {
    return {
      ...VisualContainer.mapDispatchToProps(dispatcher),
      toggleSidebar: () => dispatcher(Actions.toggleSidebar())
    };
  },
  VisualContainer.mergeProps
)(withWindowSize<GridSidebarProps>(GridSidebar));
