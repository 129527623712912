import React from 'react';
import * as _ from 'lodash';
import { Option, none, option, some } from 'ts-option';
import { AnalyzedSelectedExpression, Expr, NamePosition, Scope, SoQLType, TypedExpr, UnAnalyzedAst, UnAnalyzedSelectedExpression, isColumnRef, isFunCall } from 'common/types/soql';
import { ProjectionInfo, ViewColumnColumnRef } from '../../lib/selectors';
import { replaceAt } from 'common/util';
import { containsAggregate, existingFieldNames, hasAggregates } from '../../lib/soql-helpers';
import { fetchTranslation } from 'common/locale';
import { validateColumnFieldName } from 'common/column/utils';
import { buildSelection, dropOrderBys, updateHaving } from '../../components/VisualGroupAggregateEditor';
import { CompileAST } from '../visualContainer';
import ExpressionEditor from '../VisualExpressionEditor';
import SubtitleWithHelper from '../SubtitleWithHelper';
import AggregateAddExpr, { EditAggregateName } from '../AggregateAddExpr';
import AggregateModal from './AggregateModal';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import { EditableExpression, Eexpr } from 'common/explore_grid/types';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.visual_aggregates');

interface VisualAggregateProps {
  ast: UnAnalyzedAst;
  columns: ViewColumnColumnRef[];
  parameters: ClientContextVariable[];
  eexpr: Eexpr<Expr, TypedExpr>;
  analyzedName: string;
  unAnalyzedName: Option<string>;
  projectionInfo: ProjectionInfo;
  querySucceeded: boolean;
  scope: Scope;
  onUpdate: (newExpr: Expr, name: Option<NamePosition>) => void;
  onRemove: (name: string) => void;
  showModal: boolean;
  dismiss: () => void;
  deleteAggregate: () => void;
}

interface VisualAggregateState {
  name: Option<string>;
  errors: string[];
}

// Export needed for testing
export class VisualAggregate extends React.Component<VisualAggregateProps, VisualAggregateState> {
  state: VisualAggregateState = { name: some(this.props.analyzedName), errors: [] };

  onNameChange = (text: string) => {
    const { analyzedName, ast, eexpr, onUpdate, projectionInfo, scope } = this.props;
    const usedFieldNames = existingFieldNames(ast, projectionInfo, scope).filter(fieldName => fieldName != analyzedName);
    const errors = validateColumnFieldName(text, usedFieldNames);
    const notMatching = text !== analyzedName; // text is not the same as the current api field name
    if (_.isEmpty(errors) && notMatching) {
      const newName = {
        name: text,
        position: {
          column: 1,
          line: 1
        }
      } as NamePosition;
      onUpdate(eexpr.untyped, some(newName));
    }
    this.setState({ errors });
  };

  onUpdate = (newExpr: Expr) => {
    this.props.onUpdate(newExpr, none);
  };

  render() {
    const { columns, eexpr, onRemove, projectionInfo, querySucceeded, scope, parameters } = this.props;
    const name = this.state.name.getOrElseValue('');

    return (
      <div className="aggregate-by">
        <div className="aggregate-expression">
          <ExpressionEditor
            scope={scope}
            isTypeAllowed={(st: SoQLType) => true}
            eexpr={eexpr}
            columns={columns}
            parameters={parameters}
            update={this.onUpdate}
            remove={() => onRemove(name)}
            projectionInfo={projectionInfo}
            querySucceeded={querySucceeded}
            showRemove={false}
            showKebab />
          <EditAggregateName
            value={this.state.name.getOrElseValue('')}
            onChange={this.onNameChange}
            errors={this.state.errors} />
          {this.props.showModal && <AggregateModal
            onDismiss={this.props.dismiss}
            deleteAggregate={this.props.deleteAggregate}
            apiFieldName={name}/>}
        </div>
      </div>
    );
  }
}

export function onUpdateAggregate(
  ast: UnAnalyzedAst,
  selectedExpressions: EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[],
  index: number,
  newExpr: Expr,
  name: Option<NamePosition>
): UnAnalyzedAst {
  const selectedExpr: UnAnalyzedSelectedExpression = {
    expr: newExpr,
    name: name.orNull
  };
  const droppedSelections = [selectedExpressions[index]];
  return {
    ...ast,
    selection: {
      ...ast.selection,
      exprs: replaceAt(ast.selection.exprs, selectedExpr, index)
    },
    order_bys: dropOrderBys(droppedSelections, ast.order_bys),
    having: updateHaving(droppedSelections, ast.having)
  };
}

export function onRemoveAggregate(
  ast: UnAnalyzedAst,
  selectedExpressions: EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[],
  index: number,
  columns: ViewColumnColumnRef[]
): UnAnalyzedAst {
  const droppedSelections = [selectedExpressions[index]];
  return {
    ...ast,
    selection: buildSelection(selectedExpressions.map(({ untyped: expr }) => expr).filter((_unused, i) => i !== index), ast.group_bys, columns),
    order_bys: dropOrderBys(droppedSelections, ast.order_bys),
    having: updateHaving(droppedSelections, ast.having)
  };
}

interface VisualAggregateListProps {
  ast: UnAnalyzedAst;
  columns: ViewColumnColumnRef[];
  parameters: ClientContextVariable[];
  compileAST: CompileAST;
  projectionInfo: ProjectionInfo;
  querySucceeded: boolean;
  scope: Scope;
  selectedExpressions: Option<EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[]>;
}

interface VisualAggregateListState {
  showAggregateAddExpr: boolean;
  showModalIndex: Option<number>;
}

export default class VisualAggregateList extends React.Component<VisualAggregateListProps, VisualAggregateListState> {
  constructor(props: VisualAggregateListProps) {
    super(props);
    this.state = {
      showAggregateAddExpr: !hasAggregates(props.ast, props.scope),
      showModalIndex: none
    };
  }

  onShowAggregateAddExpr = () => {
    this.setState({ showAggregateAddExpr: true });
  };

  onHideAggregateAddExpr = () => {
    this.setState({ showAggregateAddExpr: false });
  };

  hideModal = () => {
    this.setState({ showModalIndex: none });
  };

  render() {
    const { ast, columns, compileAST, parameters, projectionInfo, querySucceeded, scope } = this.props;
    const help = (<p className="help-message forge-typography--body2" dangerouslySetInnerHTML={{__html: t('help')}}></p>);
    const items = this.props.selectedExpressions.map(selectedExpressions => {
      const aggregates = selectedExpressions
        .map((selectedExpression, index) => ({ selectedExpression, index }))
        .filter(({ selectedExpression }) => containsAggregate(scope, selectedExpression.untyped.expr))
        .map(({ selectedExpression, index}) => {
          const onUpdate = (newExpr: Expr, name: Option<NamePosition>) => {
            const newAst = onUpdateAggregate(ast, selectedExpressions, index, newExpr, name);
            if (hasAggregates(newAst, scope)) {
              this.onHideAggregateAddExpr();
            } else {
              this.onShowAggregateAddExpr();
            }
            compileAST(newAst, true);
          };

          const checkingASTforMatch = (astHaving: Expr | null, name: string): boolean => {
            if (astHaving === null) {
              return false;
            } else if (isColumnRef(astHaving)) {
              if (astHaving.value === name) {
                return true;
              }
            } else if (isFunCall(astHaving)) {
              return astHaving.args.some(arg => {
                return checkingASTforMatch(arg, name);
              });
            }
            return false;
          };

          const onRemove = (name: string) => {
            if (hasAggregates(this.props.ast, this.props.scope) && checkingASTforMatch(this.props.ast.having, name)) {
                this.setState({ showModalIndex: some(index) });
            } else {
              deleteAggregate();
            }
          };

          const deleteAggregate = () => {
            const editedAst = onRemoveAggregate(ast, selectedExpressions, index, columns);
            compileAST(editedAst, true);
          };

          const showModal = this.state.showModalIndex.match({
            some: (i) => i === index,
            none: () => false,
          });

          return (
            <VisualAggregate
              ast={ast}
              columns={columns}
              parameters={parameters}
              eexpr={{ untyped: selectedExpression.untyped.expr, typed: selectedExpression.typed.expr }}
              key={index}
              analyzedName={selectedExpression.typed.name}
              unAnalyzedName={option(_.get(selectedExpression.untyped, 'name.name'))}
              onUpdate={onUpdate}
              onRemove={onRemove}
              showModal={showModal}
              scope={scope}
              projectionInfo={projectionInfo}
              querySucceeded={querySucceeded}
              dismiss={this.hideModal}
              deleteAggregate={deleteAggregate}/>
          );
        });

      if (aggregates.length) {
        return aggregates;
      }
    }).orNull;

    return (
      <div className="vee-expr-container vee-gray-bg vee-group-aggregate vee-aggregate-by">
        <SubtitleWithHelper className="aggregate-label" title={t('aggregate_by')} help={help} />
        {items}
        {this.state.showAggregateAddExpr && (
          <AggregateAddExpr
            columns={columns}
            parameters={parameters}
            scope={scope}
            compileAST={compileAST}
            ast={ast}
            projectionInfo={projectionInfo}
            onHideAggregateAddExpr={this.onHideAggregateAddExpr} />
        )}
        <ForgeButton className="add-more">
          <button type="button" onClick={this.onShowAggregateAddExpr} data-testid="add-aggregate-expression">
          <ForgeIcon name="add" />
            {t('add')}
          </button>
        </ForgeButton>
      </div>
    );
  }
}
