import React from 'react';
import _ from 'lodash';
import { fetchTranslation } from 'common/locale';
import {
  ForgeButton,
  ForgeCard,
  ForgeCheckbox,
  ForgeDatePicker,
  ForgeDialog,
  ForgeIcon,
  ForgeIconButton,
  ForgeLabelValue,
  ForgeOption,
  ForgeRadio,
  ForgeTextField,
  ForgeTooltip,
  ForgeToolbar,
  ForgeScaffold,
  ForgeSelect
} from '@tylertech/forge-react';
import { ClientContextVariable, ClientContextVariableCreate } from 'common/types/clientContextVariable';
import { Dispatcher } from '../redux/actions';
import { connect } from 'react-redux';
import { SoQLType } from 'common/types/soql';
import * as Actions from '../redux/actions';
import { dateToString } from '../lib/soql-helpers';
import { AppState, OpenModalType, AppStateContextualEventHandlers } from '../redux/store';
import { none, Option, some, option } from 'ts-option';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getParameterFunction } from 'common/core/client_context_variables';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.parameters_editor_modal');
const u = (k: string) => fetchTranslation(k, 'shared.explore_grid.parameters_editor.data_types');

type ParameterErrors = {
  apiFieldName: boolean;
  apiFieldNameErrorMessage: string;
  displayName: boolean;
  displayNameErrorMessage: string;
  defaultValue: boolean;
  defaultValueErrorMessage: string;
};

interface DispatchProps {
  closeModal: () => void;
  onApplyChanges: () => void;
  onCodeSave: () => void;
  dispatch: Dispatcher;
}

interface StateProps {
  isOpen: boolean;
  isEditing: boolean;
  viewId: string;
  publishedViewId: Option<string>;
  parameters: ClientContextVariable[];
  parameterToEdit: Option<ClientContextVariable>;
  contextualEventHandlers: AppStateContextualEventHandlers;
}

type Props = StateProps & {
  closeModal: DispatchProps['closeModal'];
  onApplyChanges: DispatchProps['onApplyChanges'];
  onCodeSave: DispatchProps['onCodeSave'];
  addParameter: (
    viewId: string,
    parameter: ClientContextVariableCreate,
    onSuccess: () => void,
    onError: (err: any) => void
  ) => void;
  editParameter: (
    viewId: string,
    parameter: ClientContextVariableCreate,
    onSuccess: () => void,
    onError: (err: any) => void
  ) => void;
};

enum parameterErrorCodes {
  wrongDefaultType = 'CLIENT_CONTEXT_VARIABLE.DEFAULT_TYPE_INCORRECT',
  defaultTypeCheck = 'CLIENT_CONTEXT_VARIABLE.DEFAULT_DATATYPE_CHECK',
  whitespace = 'CLIENT_CONTEXT_VARIABLE.NO_WHITESPACE_NAME',
  uniqueName = 'CLIENT_CONTEXT_VARIABLE.UNIQUE_NAME'
}

export interface State {
  isEditing: boolean;
  apiFieldName?: string;
  displayName?: string;
  type: SoQLType;
  defaultValue: string;
  errors: ParameterErrors;
}

export class ParametersEditorModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isEditing: false,
      type: SoQLType.SoQLTextT,
      errors: {
        apiFieldName: false,
        apiFieldNameErrorMessage: '',
        displayName: false,
        displayNameErrorMessage: '',
        defaultValue: false,
        defaultValueErrorMessage: ''
      },
      defaultValue: ''
    };
  }

  // parameterToEdit lives in AppState. When it is set, populate this component's default state with the values of the parameter
  static getDerivedStateFromProps = (props: Props, state: State) => {
    if (props.isEditing && !state.isEditing) {
      const pte = props.parameterToEdit.get;
      return {
        ...state,
        isEditing: true,
        apiFieldName: pte.name,
        displayName: pte.displayName,
        type: pte.dataType,
        defaultValue: pte.defaultValue
      };
    } else if (!props.isEditing && state.isEditing) {
      return {
        ...state,
        isEditing: false,
        apiFieldName: undefined,
        displayName: undefined,
        type: SoQLType.SoQLTextT,
        defaultValue: ''
      };
    }
    return null;
  };

  clearErrorCodes = () => {
    this.setState(() => ({
      errors: {
        apiFieldName: false,
        apiFieldNameErrorMessage: '',
        displayName: false,
        displayNameErrorMessage: '',
        defaultValue: false,
        defaultValueErrorMessage: ''
      }
    }));
  };

  clearComponentState = () => {
    this.setState({
      isEditing: false,
      apiFieldName: undefined,
      displayName: undefined,
      type: SoQLType.SoQLTextT,
      defaultValue: ''
    });
  };

  handleErrorCodes = (code: parameterErrorCodes) => {
    this.clearErrorCodes();
    switch (code) {
      case parameterErrorCodes.wrongDefaultType: {
        const translatedMessage = t('default_type_incorrect');
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            defaultValue: true,
            defaultValueErrorMessage: translatedMessage
          }
        }));
        break;
      }
      case parameterErrorCodes.defaultTypeCheck: {
        const translatedMessage = t('default_type_check');
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            defaultValue: true,
            defaultValueErrorMessage: translatedMessage
          }
        }));
        break;
      }
      case parameterErrorCodes.whitespace: {
        const translatedMessage = t('whitespace');
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            apiFieldName: true,
            apiFieldNameErrorMessage: translatedMessage
          }
        }));
        break;
      }
      case parameterErrorCodes.uniqueName: {
        const translatedMessage = t('unique_name');
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            apiFieldName: true,
            apiFieldNameErrorMessage: translatedMessage
          }
        }));
        break;
      }
    }
  };

  onSave = () => {
    const { parameterToEdit } = this.props;
    const { apiFieldName, displayName, type, defaultValue } = this.state;
    if (!apiFieldName || !defaultValue) {
      console.error(
        `Save requested with invalid apiFieldName: ${apiFieldName} and defaultValue: ${defaultValue} values. Save functionality should have been disabled.`
      );
      return;
    }

    const newAPIParameter: ClientContextVariableCreate = {
      name: apiFieldName!,
      displayName: displayName || '',
      dataType: type,
      defaultValue: defaultValue!
    };

    const onError = (err: any) => {
      if (err.json?.error && err.json?.code) {
        this.handleErrorCodes(err.json.code);
      } else {
        console.error('Error creating new parameter: ', err);
      }
    };
    const onSuccess = () => {
      this.props.onApplyChanges();
      this.clearComponentState();
    };

    if (parameterToEdit.nonEmpty) {
      this.props.editParameter(this.props.viewId, newAPIParameter, onSuccess, onError);
    } else {
      this.props.addParameter(this.props.viewId, newAPIParameter, onSuccess, onError);
    }
  };

  getDefaultValueInput = (): any => {
    switch (this.state.type) {
      case 'text':
      case 'number': {
        return (
          <ForgeTextField invalid={this.state.errors.defaultValue}>
            <input
              type="text"
              id="input-text-default-value"
              value={this.state.defaultValue}
              onChange={(e) => this.setState({ defaultValue: e.target.value })}
              autoComplete="off"
            />
            <label htmlFor="input-text-default-value" slot="label">
              {t('default')}
            </label>
            {
            this.state.errors.defaultValue ? (
              <span slot="helper-text">{this.state.errors.defaultValueErrorMessage}</span>
            ) : null}
            {typeof this.state.defaultValue !== 'number' ? null : '' }
          </ForgeTextField>
        );
      }
      case 'calendar_date': {
        return (
          <ForgeDatePicker
            popup-classes="datatype-popup"
            on-forge-date-picker-change={(e: CustomEvent) => {
              this.setState({ defaultValue: dateToString(e.detail, SoQLType.SoQLFloatingTimestampT).get });
            }}
            allowInvalidDate={true}
          >
            <ForgeTextField>
              <input type="text" id="input-datepicker" defaultValue={this.state.defaultValue} />
              <label htmlFor="input-datepicker">{t('default')}</label>
              {this.state.errors.defaultValue ? (
                <span id="datePickerError" slot="helper-text">
                  {this.state.errors.defaultValueErrorMessage}
                </span>
              ) : null}
            </ForgeTextField>
          </ForgeDatePicker>
        );
      }
      case 'checkbox': {
        return (
          <ForgeLabelValue>
            <span slot="label">{t('default')}</span>
            <span slot="value">
              <div
                role="radiogroup"
                aria-label={t('aria_label')}
                id="radio-group-div"
                // typing needed because by default e:React.FormEvent<HTMLDivElement>
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({ defaultValue: e.target.value })
                }
              >
                <ForgeRadio>
                  <input
                    type="radio"
                    id="radio-1"
                    name="radios"
                    value="true"
                    defaultChecked={this.state.defaultValue == 'true'}
                  />
                  <label htmlFor="radio-1">{t('true')}</label>
                </ForgeRadio>
                <ForgeRadio>
                  <input
                    type="radio"
                    id="radio-2"
                    name="radios"
                    value="false"
                    defaultChecked={this.state.defaultValue == 'false'}
                  />
                  <label htmlFor="radio-2">{t('false')}</label>
                </ForgeRadio>
                {this.state.errors.defaultValue ? (
                  <span slot="helper-text">{this.state.errors.defaultValueErrorMessage}</span>
                ) : null}
              </div>
            </span>
          </ForgeLabelValue>
        );
      }
    }
  };

  getPreview = (): any => {
    switch (this.state.type) {
      case 'text':
      case 'number': {
        return (
          <ForgeTextField>
            <input
              type="text"
              id="text-preview"
              value={this.state.defaultValue}
              disabled
              aria-label={t('preview')}
            />
            <label htmlFor="input-text-default-value" slot="label">
              {this.state.displayName}
            </label>
          </ForgeTextField>
        );
      }
      case 'calendar_date': {
        return (
          <ForgeDatePicker
            popup-classes="datatype-popup"
            disabled={true}
            allowInvalidDate={true}
            value={this.state.defaultValue}
          >
            <ForgeTextField>
              <input type="text" id="datepicker-preview" readOnly />
              <label htmlFor="input-datepicker">{this.state.displayName}</label>
            </ForgeTextField>
          </ForgeDatePicker>
        );
      }
      case 'checkbox': {
        return (
          <ForgeCheckbox dense>
            <input
              type="checkbox"
              id={`parameter-${this.state.apiFieldName}-checkbox-preview`}
              checked={this.state.defaultValue === 'true' ? true : false}
              disabled
            />
            <label htmlFor={`parameter-${this.state.apiFieldName}-checkbox`}>{this.state.displayName}</label>
          </ForgeCheckbox>
        );
      }
    }
  };

  onFieldNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (RegExp(/\s/).test(e.target.value)) {
      // check for spaces
      this.handleErrorCodes(parameterErrorCodes.whitespace);
    } else if (this.props.parameters.find((pm) => !pm.inherited && pm.name === e.target.value)) {
      // check for uniqueness
      this.handleErrorCodes(parameterErrorCodes.uniqueName);
    } else {
      // if there are no errors, clear the error codes
      this.clearErrorCodes();
    }

    this.setState({ apiFieldName: e.target.value });
  };

  onDataTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        defaultValue: false,
        defaultValueErrorMessage: ''
      },
      type: e.target.value as SoQLType,
      defaultValue: ''
    }));
  };

  getCodeSnippet = () => {
    const { apiFieldName } = this.state;
    const { viewId, publishedViewId } = this.props;

    return getParameterFunction(apiFieldName || '', publishedViewId.getOrElseValue(viewId));
  };

  copyCodeSnippet = () => {
    this.props.onCodeSave();
  };

  onClose = () => {
    this.clearComponentState();
    this.clearErrorCodes();
    this.props.closeModal();
  };

  render() {
    const { apiFieldName, defaultValue } = this.state;
    const { isOpen, isEditing } = this.props;

    const disableSave = !(apiFieldName?.length === 0 || defaultValue);
    const codeSnippet = this.getCodeSnippet();

    const dialogAttributes = new Map([['aria-labelledby', 'parameter-dialog-title']]);

    if (!isOpen) {
      return null;
    }

    return (
      <ForgeDialog open={true} onDismiss={this.onClose} options={{ dialogAttributes }}>
        <div className="parameters-editor-dialog">
          <div className="header">
            <h1 slot="start" id="parameter-dialog-title" className="forge-typography--title">
              {!isEditing ? t('title') : t('edit_title')}
            </h1>
          </div>
          <div className="forge-dialog-container">
            <div className="forge-dialog-body__padding">
              <ForgeTextField invalid={this.state.errors.apiFieldName}>
                <input
                  type="text"
                  id="input-text-API-field-name"
                  disabled={isEditing}
                  defaultValue={this.state.apiFieldName}
                  onChange={(e) => this.onFieldNameChange(e)}
                  autoComplete="off"
                />
                <label htmlFor="input-text-API-field-name" slot="label">
                  {t('api')}
                </label>
                {this.state.errors.apiFieldName ? (
                  <span slot="helper-text">{this.state.errors.apiFieldNameErrorMessage}</span>
                ) : null}
              </ForgeTextField>

              <ForgeTextField invalid={this.state.errors.displayName}>
                <input
                  type="text"
                  id="input-text-display-name"
                  defaultValue={this.state.displayName}
                  onChange={(e) => this.setState({ displayName: e.target.value })}
                  autoComplete="off"
                />
                <label htmlFor="input-text-display-name" slot="label">
                  {t('display')}
                </label>
                {this.state.errors.displayName ? (
                  <span slot="helper-text">{this.state.errors.displayNameErrorMessage}</span>
                ) : null}
              </ForgeTextField>

              <ForgeSelect
                id="input-datatype-select"
                label={t('type')}
                value={this.state.type}
                popupClasses={'datatype-popup'}
                disabled={isEditing}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onDataTypeChange(e)}
              >
                <ForgeOption value="text">{u('text')}</ForgeOption>
                <ForgeOption value="number">{u('number')}</ForgeOption>
                <ForgeOption value="calendar_date">{u('calendar_date')}</ForgeOption>
                <ForgeOption value="checkbox">{u('checkbox')}</ForgeOption>
                {this.state.errors.displayName ? (
                  <span slot="helper-text">{this.state.errors.displayNameErrorMessage}</span>
                ) : null}
              </ForgeSelect>
              {this.getDefaultValueInput()}
            </div>
            <div className="parameter_modal_right_panal">
              <ForgeCard className="preview" outlined id={'parameter-dialog-soql-card'}>
                <ForgeScaffold>
                  <div slot="header" className="forge-card-header-container">
                    <h3 className="forge-typography--subtitle1">Preview</h3>
                  </div>
                  <div className="parameter-modal-soql-body" slot="body">
                    {this.getPreview()}
                  </div>
                </ForgeScaffold>
              </ForgeCard>
              <ForgeCard outlined id={'parameter-dialog-soql-card'}>
                <ForgeScaffold>
                  <div slot="header" className="forge-card-header-container">
                    <h3 className="forge-typography--subtitle1">{t('soql_usage_title')}</h3>
                  </div>
                  <div className="parameter-modal-soql-body" slot="body">
                    <p className="forge-typography--body2">{t('soql_description')}</p>
                  </div>
                  <div slot="footer" className="forge-card-footer">
                    <ForgeTextField>
                      <input
                        type="text"
                        className="parameter-modal-soql-text"
                        disabled
                        value={codeSnippet}
                        aria-label={t('soql_snippet')}
                      />
                      <CopyToClipboard text={codeSnippet} onCopy={this.copyCodeSnippet}>
                        <ForgeIconButton>
                          <button
                            type="button"
                            slot="trailing"
                            aria-label={t('save_to_clipbaord')}
                            onClick={this.copyCodeSnippet}
                            className="tyler-icons"
                          >
                            <ForgeIcon name="content_copy" />
                            <ForgeTooltip id="parameter-modal-soql-usage-tooltip" position={'bottom'}>
                              {t('copy')}
                            </ForgeTooltip>
                          </button>
                        </ForgeIconButton>
                      </CopyToClipboard>
                    </ForgeTextField>
                  </div>
                </ForgeScaffold>
              </ForgeCard>
            </div>
          </div>
          <div className="footer">
            <ForgeToolbar inverted={true} className="footer-toolbar">
              <div slot="end">
                <ForgeButton type="outlined">
                  <button id="cancel-button" onClick={this.onClose}>
                    {t('discard_changes')}
                  </button>
                </ForgeButton>
                <ForgeButton type="raised">
                  <button id="accept-button" onClick={this.onSave} disabled={disableSave}>
                    {t('apply_changes')}
                  </button>
                </ForgeButton>
              </div>
            </ForgeToolbar>
          </div>
        </div>
      </ForgeDialog>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    isOpen:
      state.openModal.type == OpenModalType.NEW_PARAMETER ||
      state.openModal.type == OpenModalType.EDIT_PARAMETER,
    isEditing: state.openModal.type == OpenModalType.EDIT_PARAMETER,
    viewId: state.view.id,
    publishedViewId: option(state.view.publishedViewUid),
    parameters: state.clientContextInfo.variables,
    parameterToEdit: state.openModal.modalData.nonEmpty
      ? some(state.openModal.modalData.get.parameterToEdit)
      : none,
    contextualEventHandlers: state.contextualEventHandlers
  };
};

const mapDispatchToProps = (dispatch: Dispatcher): DispatchProps => {
  return {
    closeModal: () => {
      dispatch(Actions.closeModal());
    },
    onApplyChanges: () => {
      dispatch(Actions.closeModal());
      dispatch(Actions.showToast(t('save_toast'), none));
    },
    onCodeSave: () => {
      dispatch(Actions.showToast(t('save_code_toast'), none));
    },
    dispatch: dispatch
  };
};

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps) => {
  return {
    ...stateProps,
    closeModal: dispatchProps.closeModal,
    onApplyChanges: dispatchProps.onApplyChanges,
    onCodeSave: dispatchProps.onCodeSave,
    addParameter: (
      viewId: string,
      parameter: ClientContextVariableCreate,
      onSuccess: () => void,
      onError: (err: any) => void
    ) => {
      dispatchProps.dispatch(
        stateProps.contextualEventHandlers.addParameter(viewId, parameter, onSuccess, onError)
      );
    },
    editParameter: (
      viewId: string,
      parameter: ClientContextVariableCreate,
      onSuccess: () => void,
      onError: (err: any) => void
    ) => {
      dispatchProps.dispatch(
        stateProps.contextualEventHandlers.editParameter(viewId, parameter, onSuccess, onError)
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ParametersEditorModal);
