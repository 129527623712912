import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { UnAnalyzedAst, UnAnalyzedSelectedExpression, AnalyzedSelectedExpression } from 'common/types/soql';
import { AppState } from '../redux/store';
import { EditableExpression, Tab } from 'common/explore_grid/types';
import { Option } from 'ts-option';
import { buildSelection, dropOrderBys } from './VisualGroupAggregateEditor';
import { getEditableSelectedExpressions, getColumns, getUnAnalyzedAst, lastInChain, ViewColumnColumnRef } from '../lib/selectors';
import { hasGroupOrAggregate } from '../lib/soql-helpers';
import ClearExpr from './ClearExpr';

interface ClearAllGroupsProps {
  columns: ViewColumnColumnRef[];
  clearEnabled: boolean;
  selectedExprs: Option<EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[]>;
}

function ClearAllGroups({ clearEnabled, columns, selectedExprs }: ClearAllGroupsProps) {
  const getClearedAST = (ast: UnAnalyzedAst): UnAnalyzedAst => {
    return {
      ...ast,
      group_bys: [],
      having: null,
      selection: buildSelection([], [], columns),
      order_bys: selectedExprs.map((se) => dropOrderBys(se, [])).getOrElseValue(ast.order_bys)
    };
  };
  return (<ClearExpr
    clearEnabled={clearEnabled}
    getClearedAST={getClearedAST}
    tab={Tab.Aggregate} />);
}

const mapStateToProps = (state: AppState): ClearAllGroupsProps => {
  const { query, scope } = state;
  const columns = getColumns(query);
  const selectedExprs = getEditableSelectedExpressions(query);
  const clearEnabled = hasGroupOrAggregate(getUnAnalyzedAst(query).map(lastInChain), scope.getOrElseValue([]));

  return {
    clearEnabled,
    columns,
    selectedExprs
  };
};

export default connect(mapStateToProps)(ClearAllGroups);
