import { CollocateJobFailed, CollocationStatus } from 'common/core/collocation';
import { GuidanceSummaryV2 } from 'common/types/approvals';
import { QueryAnalysisResult, QueryAnalysisSucceeded, QueryCompilationResult, QueryCompilationSucceeded } from 'common/types/compiler';
import { PhxChannel, socket } from 'common/types/dsmapi';
import { AnalyzedSelectedExpression, BinaryTree, Scope, UnAnalyzedAst } from 'common/types/soql';
import { View } from 'common/types/view';
import { ViewColumn } from 'common/types/viewColumn';
import { ClientContextVariable, ClientContextVariableCreate } from 'common/types/clientContextVariable';
import { Tab, QueryStatus } from 'common/explore_grid/types';
import { applyMiddleware, compose, createStore, Store, Dispatch, Middleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { none, Option, option } from 'ts-option';
import {
  Action,
  DEFAULT_QUERY,
  Dispatcher,
  resumeAfterReconnect,
  runDefaultQuery,
  scopeChanged,
  setDefaultQueryText,
  editParameterOnWorkingCopy,
  createParameterOnWorkingCopy,
  deleteParameterOnWorkingCopy,
  replaceAllParametersOnWorkingCopy,
  getApprovalsGuidance,
  getColumnsForQuery
} from './actions';
import reducer from './reducer';
import { RemoteStatusInfo } from './statuses';
import { sortClientContextVariables } from 'common/core/client_context_variables';


export enum OpenModalType {
  SAVED_QUERY = 'saved_query',
  NEW_PARAMETER = 'new_parameter',
  EDIT_PARAMETER = 'edit_parameter',
  NONE = 'none'
}

// interfaces for modal data types
interface paramaterToEdit {
  parameterToEdit: ClientContextVariable
}

// add any data types needed here
export type ModalData = paramaterToEdit;

interface OpenModal {
  type: OpenModalType,
  modalData: Option<ModalData>
}

export const DEFAULT_PAGE_SIZE = 100;

const devToolsConfig = {
  actionsDenylist: [],
  name: 'Grid'
};

interface Window {
  initialState: {
    view: View;
  };
}

interface ChannelJoinResponse {
  scope: Scope;
  default_query: string;
}

export interface QueryFailureDetails {
  message: string;
  errorCode: string;
  data: unknown;
}
export interface QueryFailure {
  type: QueryStatus.QUERY_FAILURE;
  details: QueryFailureDetails;
}

interface QueryMetaInProgress {
  type: QueryStatus.QUERY_META_IN_PROGRESS;
}
export interface QueryMetaSuccess {
  type: QueryStatus.QUERY_META_SUCCESS;
  rowCount: number;
  fromAst: BinaryTree<UnAnalyzedAst>;
  clientContextVariables: ClientContextVariable[];
}
export type QueryMeta = QueryMetaInProgress | QueryMetaSuccess;

export type Row = any;
export type QuerySuccess = {
  type: QueryStatus.QUERY_SUCCESS;
  relevanceId: string;
  compiled: QueryCompilationSucceeded;
  analyzed: Option<QueryAnalysisSucceeded>; // This is an option only because it's currently feature-flagged.
  rows: Row[];
  meta: QueryMeta;
};
export type QueryResult = QuerySuccess | QueryFailure;

export type PaginationState = {
  pageSize: number;
  currentPage: number;
  // when we've compiled a new query that prompts a page change, but haven't yet run it
  // this is the page information that will be put in place once it's run
  pageSizePendingRun: Option<number>;
  currentPagePendingRun: Option<number>;
};

export interface Query {
  text: Option<string>;
  typedWhileCompilationInProgress: boolean;
  compilationResult: Option<QueryCompilationResult>;
  analysisResult: Option<QueryAnalysisResult>;
  isQueryInProgress: boolean;
  queryResult: Option<QueryResult>;
  paginationState: PaginationState;
}

export interface LocationParams {
  tab: Tab;
  queryString: Option<string>;
  subTab: Option<string>;
}

export enum SaveStatus {
  IDLE = 'IDLE',
  SAVING = 'SAVING',
  SAVED = 'SAVED',
  ERRORED = 'ERRORED'
}
interface SaveInfo {
  saveStatus: SaveStatus;
  defaultQuery: string;
}
export interface CollocationInfo {
  collocationStatus: CollocationStatus;
  collocationJobId: Option<string>;
  collocationResult: Option<CollocateJobFailed>;
  joinTargets: string[];
}
interface ParentInfo {
  canReadFromAllParents: boolean;
  mainParent: Option<View>; // optional because user may not have permission to see it
}
interface ApplyInfo {
  lastClickedApply: Option<Date>;
}

interface ClientContextInfo {
  variables: ClientContextVariable[];
}

interface SoQLEditorInfo {
  editorInstance: Option<any>
}

// all the properties a healthy, growing SoQL View Column needs
export interface VQEColumn {
  fieldName: ViewColumn['fieldName'];
  description: ViewColumn['description'];
  name?: ViewColumn['name'];
  flags?: ViewColumn['flags'];
  width?: ViewColumn['width'];
  format: ViewColumn['format'];
  position: ViewColumn['position']
  dataTypeName: ViewColumn['dataTypeName']
}

export type ViewWithVQEColumns = Omit<View, 'columns'> & {
  columns: VQEColumn[];
};

export type ColumnProvider = (an: AnalyzedSelectedExpression) => Option<Partial<ViewColumn>>;
export interface ContextualEventHandlers {
  editColumnMetadata: (metadataType: 'fieldName' | 'name' | 'description', column: Partial<ViewColumn>) => void;
  formatColumn: (column: VQEColumn, columnUpdated: (updatedColumn?: VQEColumn) => void) => void;
  handleColumnWidthChange: (column: VQEColumn, changeVQEColumnStateCB: () => void) => void;
  getColumn: ColumnProvider;
  resolveColumnMetadata: (qs: QueryCompilationSucceeded) => Promise<VQEColumn[]>;
  addParameter: (viewId: string, parameter: ClientContextVariableCreate, onSuccess: () => void, onError: (err: any) => void) => (dispatch: Dispatcher) => void;
  editParameter: (viewId: string, parameter: ClientContextVariableCreate, onSuccess: () => void, onError: (err: any) => void) => (dispatch: Dispatcher) => void;
  deleteParameter: (viewId: string, parameterName: string, onSuccess: () => void, onError: (err: any) => void) => (dispatch: Dispatcher) => void;
  replaceAllParameters: (viewId: string, parameters: ClientContextVariableCreate[], considerUndoable: boolean, onSuccess: () => void, onError: (err: any) => void)  => (dispatch: Dispatcher) => void;
  updateUndoRedoHistory: (history: UndoRedoHistory) => void;
  undoRedoColumnMetadata: (columnMetadata: VQEColumn[], handleUndoRedo: () => void) => void;
}

export interface ToastState {
  isOpen: boolean;
  message: Option<string>;
  icon: Option<JSX.Element>;
  duration?: number;
}

export interface UndoRedoInfo {
  queryText: string;
  clientContext: ClientContextInfo;
  columnMetadata: VQEColumn[];
}

export interface UndoRedoHistory {
  undo: UndoRedoInfo[];
  redo: UndoRedoInfo[];
  justApplied: Option<UndoRedoInfo>;
}

export interface AppStateContextualEventHandlers {
  editColumnMetadata?: ContextualEventHandlers['editColumnMetadata'];
  formatColumn?: ContextualEventHandlers['formatColumn'];
  getColumn?: ContextualEventHandlers['getColumn'];
  resolveColumnMetadata: ContextualEventHandlers['resolveColumnMetadata'];
  addParameter: ContextualEventHandlers['addParameter'];
  editParameter: ContextualEventHandlers['editParameter'];
  deleteParameter: ContextualEventHandlers['deleteParameter'];
  replaceAllParameters?: ContextualEventHandlers['replaceAllParameters'];
  updateUndoRedoHistory: ContextualEventHandlers['updateUndoRedoHistory'];
  undoRedoColumnMetadata: ContextualEventHandlers['undoRedoColumnMetadata'];
}

export interface AppState {
  channel: PhxChannel;
  fourfour: string;
  fourfourToQuery: string;
  query: Query;
  scope: Option<Scope>;
  baseLocation: string;
  locationParams: LocationParams;
  view: View;
  editing: boolean;
  undocked: boolean;
  modalTargetWindow: ReturnType<typeof window.open>; // hack to get around Window being modified globally
  isSidebarOpen: boolean;
  openModal: OpenModal;
  parentInfo: ParentInfo;
  saveInfo: SaveInfo;
  collocationInfo: Option<CollocationInfo>;
  remoteStatusInfo: Option<RemoteStatusInfo>;
  applyInfo: ApplyInfo;
  clientContextInfo: ClientContextInfo;
  soqlEditorInfo: SoQLEditorInfo;
  toastState: ToastState;
  contextualEventHandlers: AppStateContextualEventHandlers;
  joinsEnabled: boolean;
  onHistoryUpdated: (url: string) => void;
  columns: VQEColumn[];
  undoRedoInfo: UndoRedoHistory;
  approvalsGuidance: GuidanceSummaryV2 | undefined;
}


type HistoryUpdater = (newUrl: string) => void;
export interface ExternalVQEData {
  view: View;
  columns: VQEColumn[];
  fourfourToQuery: string;
  editing: boolean;
  parentView: Option<View>;
  canReadFromAllParents: boolean;
  queryString: Option<string>;
  joinsEnabled: boolean;
  clientContext: ClientContextVariable[];
  undoRedoHistory?: UndoRedoHistory;
}

export enum UndoRedo {
  UNDO = 'undo',
  REDO = 'redo'
}

type ExternalDataProvider = () => ExternalVQEData;

export default function create(
  onHistoryUpdated: HistoryUpdater,
  getExternalData: ExternalDataProvider,
  contextualEventHandlers: Partial<ContextualEventHandlers>,
  middlewares: Middleware<any, AppState, Dispatch<Action>>[] = []
): Store<AppState> {
  const { view, columns, fourfourToQuery, parentView, canReadFromAllParents, editing, queryString, joinsEnabled, clientContext, undoRedoHistory } = getExternalData();
  // the view uid that we should use as the source of data
  // when in editing mode, we use the parent id, as the user can alter/remove filters that are baked into the current saved view
  const chan: PhxChannel = socket(fourfourToQuery).channel(`query_compiler:${fourfourToQuery}`);

  // Cull it back to /explore for building new URLs off.
  // path = "/:datasetOrCategory/:name/:fourfour/explore"
  const pathRegexp = /(?<path>\/(?:dataset|.+)\/(?:.+)\/(?:\w{4}-\w{4})\/explore)/;

  const resolveColumnMetadata = (compSuccess: QueryCompilationSucceeded) => {
    return getColumnsForQuery(fourfourToQuery, view!.id, clientContext, compSuccess);
  };
  const addParameter = (viewId: string, parameter: ClientContextVariableCreate, onSuccess: () => void, onError: (err: any) => void) => (dispatch: Dispatcher) => {
    dispatch(createParameterOnWorkingCopy(viewId, view!.publishedViewUid || viewId, parameter, onSuccess, onError));
  };
  const editParameter = (viewId: string, parameter: ClientContextVariableCreate, onSuccess: () => void, onError: (err: any) => void) => (dispatch: Dispatcher) => {
    dispatch(editParameterOnWorkingCopy(viewId, view!.publishedViewUid || viewId, parameter, onSuccess, onError));
  };
  const deleteParameter = (viewId: string, parameterName: string, onSuccess: () => void, onError: (err: any) => void) => (dispatch: Dispatcher) => {
    dispatch(deleteParameterOnWorkingCopy(viewId, parameterName, onSuccess, onError));
  };
  const replaceAllParameters = (viewId: string, parameters: ClientContextVariableCreate[], considerUndoable: boolean, onSuccess: () => void, onError: (err: any) => void) => (dispatch: Dispatcher) => {
    dispatch(replaceAllParametersOnWorkingCopy(viewId, parameters, considerUndoable, onSuccess, onError));
  };

  const appStateContextualEventHandlers: AppStateContextualEventHandlers = {
    resolveColumnMetadata,
    addParameter,
    editParameter,
    deleteParameter,
    replaceAllParameters,
    updateUndoRedoHistory: (_: UndoRedoHistory) => {},
    undoRedoColumnMetadata: (_: VQEColumn[], callback: () => void) => {
      // although there is no interface for editing column metadata in explore mode
      // on initial load, we sometimes falsely detect differences in columns
      // due to the difference between core.columns proccessed through .to_json in ruby frontend
      // and core columns returned via websocket by dsmapi (ex: description: "" gets removed by .to_json)
      // this makes sure that even if we think the query + columns have changed, we still handle the query change
      callback();
    },
    ...contextualEventHandlers,
  };
  const initialState: AppState = {
    channel: chan,
    isSidebarOpen: false,
    fourfourToQuery: fourfourToQuery,
    fourfour: view!.id,
    scope: none,
    query: {
      text: queryString,
      typedWhileCompilationInProgress: false,
      isQueryInProgress: false,
      compilationResult: none,
      analysisResult: none,
      queryResult: none,
      paginationState: {
        pageSize: DEFAULT_PAGE_SIZE,
        currentPage: 1,
        pageSizePendingRun: none,
        currentPagePendingRun: none
      }
    },
    baseLocation: window.location.pathname.match(pathRegexp)?.groups?.path || '',
    locationParams: {
      queryString: queryString,
      tab: Tab.Filter,
      subTab: none
    },
    view: view!,
    editing: editing,
    undocked: false,
    modalTargetWindow: null,
    parentInfo: {
      mainParent: parentView,
      canReadFromAllParents: option(canReadFromAllParents).getOrElseValue(false)
    },
    saveInfo: {
      saveStatus: SaveStatus.IDLE,
      defaultQuery: queryString.getOrElseValue(DEFAULT_QUERY) // maybe more accurate to call this 'starting query'
    },
    collocationInfo: none,
    remoteStatusInfo: none,
    applyInfo: { lastClickedApply: none },
    clientContextInfo: { variables: sortClientContextVariables(clientContext, false) },
    soqlEditorInfo: { editorInstance: none },
    toastState: { isOpen: false, message: none, icon: none },
    contextualEventHandlers: appStateContextualEventHandlers,
    joinsEnabled,
    onHistoryUpdated,
    openModal: {
      type: OpenModalType.NONE,
      modalData: none
    },
    columns: columns,
    undoRedoInfo: {
      undo: undoRedoHistory?.undo || [],
      redo: undoRedoHistory?.redo || [],
      justApplied: undoRedoHistory?.justApplied || none
    },
    approvalsGuidance: undefined,
  };

  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(devToolsConfig)) ||
    compose;
  const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware, ...middlewares));
  const store = createStore(reducer(), initialState, enhancer);
  const dispatch: Dispatcher = store.dispatch;
  chan
    .join()
    .receive('ok', (r: ChannelJoinResponse) => {
      const state = store.getState();

      dispatch(scopeChanged(r.scope));
      if (state.remoteStatusInfo.isEmpty) {
        // we're joining because you just loaded the page
        dispatch(setDefaultQueryText(r.default_query));
        dispatch(runDefaultQuery(r.default_query));
        dispatch(getApprovalsGuidance(state.fourfour));
      } else {
        // we're joining after a disconnect
        dispatch(resumeAfterReconnect(state));
      }
    })
    .receive('error', (r: any) => console.error('failed to join compiler channel', r));

  return store;
}
