import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { UnAnalyzedAst, BinaryTree } from 'common/types/soql';
import Button, { VARIANTS } from 'common/components/Button';
import { AppState } from '../redux/store';
import I18n from 'common/i18n';
import { Tab } from 'common/explore_grid/types';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { Option, none, some } from 'ts-option';
import { getUnAnalyzedAst, lastInChain, replaceLastInChain } from '../lib/selectors';
import { Dispatcher, compileAndRunAst } from '../redux/actions';
import { ForgeButton } from '@tylertech/forge-react';
import { ForgeIcon } from '@tylertech/forge-react';

const t = (k: string) => I18n.t(k, { scope: 'shared.explore_grid.grid_datasource.clear_all' });

interface StateProps {
  maybeAstChain: Option<BinaryTree<UnAnalyzedAst>>;
  fourfour: string;
}

interface DispatchProps {
  compileAndRunNewAst: (fourfour: string, newChain: BinaryTree<UnAnalyzedAst>) => void
}

interface ExternalProps {
  tab: Tab;
  clearEnabled: boolean;
  getClearedAST: (ast: UnAnalyzedAst) => UnAnalyzedAst;
}

type ClearExprProps = StateProps & DispatchProps & ExternalProps;

interface ClearExprState {
  savedAst: Option<UnAnalyzedAst>;
}

export class ClearExpr extends React.Component<ClearExprProps, ClearExprState> {
  state = {
    savedAst: none
  };

  componentDidUpdate(prevProps: ClearExprProps) {
    const { clearEnabled } = this.props;
    const hideUndo = clearEnabled && !prevProps.clearEnabled;
    if (hideUndo && this.state.savedAst.isDefined) {
      this.setState({ savedAst: none });
    }
  }

  onClearAll = () => {
    const { maybeAstChain, getClearedAST, compileAndRunNewAst, fourfour } = this.props;
    const maybeAst = maybeAstChain.map(lastInChain);
    this.setState({ savedAst: maybeAst });
    maybeAstChain.forEach(chain => {
      maybeAst.match({
        none: _.noop,
        some: (ast: UnAnalyzedAst) => {
          const newChain = replaceLastInChain(chain, getClearedAST(ast));
          compileAndRunNewAst(fourfour, newChain);
        }
      });
    });
  };

  onUndoClear = () => {
    const { maybeAstChain, compileAndRunNewAst, fourfour  } = this.props;
    maybeAstChain.forEach(chain => {
      this.state.savedAst.match({
        none: _.noop,
        some: (ast: UnAnalyzedAst) => {
          const newChain = replaceLastInChain(chain, { ...ast });
          compileAndRunNewAst(fourfour, newChain);
        }
      });
    });
    this.setState({ savedAst: none });
  };

  render() {
    const { tab, clearEnabled } = this.props;
    const showUndo = this.state.savedAst.isDefined;
    return (<span className="datasource-clear-all-expr datasource-left-subtitle most-left-subtitle">
      <span className="bar">|</span>
      <ForgeButton>
        <button
          disabled={!clearEnabled}
          className={tab}
          onClick={this.onClearAll}>
          <ForgeIcon name="close_circle" />
          <span className="clear-btn-text">{t('clear')}</span>
        </button>
      </ForgeButton>

      {showUndo &&
        <ForgeButton>
          <button
            className="clear-all-undo"
            onClick={this.onUndoClear}>
            <ForgeIcon name="undo" />
            {t('undo')}
          </button>
        </ForgeButton>}
    </span>);
  }
}

const mapStateToProps = (state: AppState, ownProps: ExternalProps): StateProps & ExternalProps => {
  return {
    ...ownProps,
    maybeAstChain: getUnAnalyzedAst(state.query),
    fourfour: state.fourfour
  };
};

const mapDispatchToProps = (dispatch: Dispatcher, props: ExternalProps) => {
  return {
    compileAndRunNewAst: (fourfour: string, newChain: BinaryTree<UnAnalyzedAst>) => dispatch(compileAndRunAst(fourfour, newChain, none, some(props.tab)))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClearExpr);
