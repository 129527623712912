import React, { Fragment, PropsWithChildren } from 'react';
import { fetchTranslation } from 'common/locale';
import { compilationFailure, isCompiling } from '../../lib/selectors';
import { Query } from '../../redux/store';
import Compiling from '../Compiling';
import CompilerError from './CompilerError';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.compiler_errors');

type Props = PropsWithChildren<{
  query: Query;
  requireCompilationSuccess?: boolean;
}>;

const WithHandlingOfNonVisualStates: React.FC<Props> = ({ query, requireCompilationSuccess, children }) => {

  if (isCompiling(query)) {
    return <Compiling />;
  }

  const failure = compilationFailure(query.compilationResult);

  if (requireCompilationSuccess && failure.isDefined) {
    return (
      <div className="alert error">
        {t('compilation_failed')}
        <CompilerError error={failure.get} />
      </div>
    );
  }

  return <div>{children}</div>;
};

export default WithHandlingOfNonVisualStates;
